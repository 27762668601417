import { useTranslation } from "next-i18next";
import router from "next/router";
import { useRef } from "react";
import { useInView } from "react-intersection-observer";
import Button from "../Button";

interface SlideProps {
  number: string;
  title: string;
  subtitle: string;
  description: string;
  description2?: string;
  buttonLabel: string | null;
  buttonHrefLink?: string | null;
  buttonRouterLink?: string | null;
  image?: string;
}

function Slide(props: SlideProps) {
  const {
    number,
    title,
    subtitle,
    description,
    description2 = null,
    buttonLabel,
    buttonHrefLink = null,
    buttonRouterLink = null,
    image,
  } = props;

  return (
    <div className="!flex md:h-[550px] h-auto max-w-[1440px] w-full xl:px-[117px] md:px-10 px-4 mx-auto md:flex-row flex-col-reverse py-[80px] md:py-auto bg-black">
      <div className="md:w-[50%] w-full">
        <div className="text-primary md:display-4 display-6 md:py-auto pb-10">
          {number}
        </div>
        <div className="md:heading-1 heading-2">{title}</div>
        <div className="md:emphasisText bodyText mt-[12px]">{subtitle}</div>
        <div className="bodyText mt-10 md:max-w-[488px] w-full">
          <p>{description}</p>
          {description2 && <p className="mt-8">{description2}</p>}
        </div>
        {buttonLabel && (
          <div className="mt-[48px]">
            <Button
              href={buttonHrefLink}
              onClick={() => (buttonRouterLink ? router.push(`/quests`) : null)}
              label={buttonLabel}
              type="primary"
              svgIcon="Arrowright"
            />
          </div>
        )}
      </div>
      {/* assests for the mobile version */}
      <div className="self-center md:hidden">
        <img
          src={`/icons/Homepage/${image}`}
          alt=""
          className="w-[60%] m-auto"
        />
      </div>
    </div>
  );
}

function VerticalSlides() {
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("home");
  const imageRef = useRef(null);

  const { ref: firstContainer, inView: firstInView } = useInView({
    threshold: 0.5,
  });

  const { ref: secondContainer, inView: secondInView } = useInView({
    threshold: 0.5,
  });

  const { ref: thirdContainer, inView: thirdInView } = useInView({
    threshold: 0,
  });

  const getImage = () => {
    if (firstInView) return "learn.png";
    else if (secondInView) return "mobile.png";
    else if (thirdInView) return "earn.png";
    // default image should be the firstone
    return "learn.png";
  };

  return (
    <div className="flex justify-center w-full bg-black" id="1">
      <div className="md:w-[1441px] w-full pt-20">
        <div className="w-full xl:px-[117px] md:px-10 px-4 text-white">
          <div className="heading-3">{t("knowledge.tagline")}</div>
          <div className="mt-5 lg:display-4 display-5 lg:w-[735px] w-full uppercase">
            {t("knowledge.title")}
          </div>
        </div>
        <div className="relative row row-col h-auto left-0 z-50 w-full mt-4 bg-black md:mt-0 text-white">
          <div className="md:w-[35%] w-full absolute h-[100%] left-[65%] top-[150px] md:block hidden">
            <img
              className="sticky top-[200px] left-[100px] max-w-[250px] mb-[280px]"
              src={`/icons/Homepage/${getImage()}`}
              alt=""
              ref={imageRef}
            />
          </div>
          <div ref={firstContainer}>
            <Slide
              number={t("knowledge.slider.slide1.number")}
              title={t("knowledge.slider.slide1.title")}
              subtitle={t("knowledge.slider.slide1.subtitle")}
              description={t("knowledge.slider.slide1.description")}
              buttonLabel={tCommon("GotoLedgerAcademy")}
              image="learn.png"
              buttonHrefLink="https://www.ledger.com/academy"
            />
          </div>
          <div ref={secondContainer}>
            <Slide
              number={t("knowledge.slider.slide2.number")}
              title={t("knowledge.slider.slide2.title")}
              subtitle={t("knowledge.slider.slide2.subtitle")}
              description={t("knowledge.slider.slide2.description.part1")}
              description2={t("knowledge.slider.slide2.description.part2")}
              buttonLabel={null}
              image="mobile.png"
            />
          </div>
          <div ref={thirdContainer}>
            <Slide
              number={t("knowledge.slider.slide3.number")}
              title={t("knowledge.slider.slide3.title")}
              subtitle={t("knowledge.slider.slide3.subtitle")}
              description={t("knowledge.slider.slide3.description")}
              buttonLabel={tCommon("chooseQuest")}
              image="earn.png"
              buttonRouterLink="/quests"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerticalSlides;
