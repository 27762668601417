import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { IClassroom, IPresentationQuest } from "../../src/interfaces";
import QuestsList from "./QuestsList";
import ButtonLink from "../Link";
import {
  getHomeQuestsStatusWithoutConnexion,
  getMoreQuestsStatus,
  sortHomeQuestsWithoutConnexion,
} from "../../helpers";
import { useLoginContext } from "../../hooks";
import Classroom from "./Classroom";

interface HomeQuestsProps {
  quests: IPresentationQuest[];
  serverDate: Date;
  classrooms: IClassroom[];
}

function HomeQuests({ quests, serverDate, classrooms }: HomeQuestsProps) {
  const { t } = useTranslation("home");
  const { t: tCommon } = useTranslation("common");
  const router = useRouter();
  const { isReadyToQuest } = useLoginContext();
  const [academyQuests, setAcademyQuests] = useState<IPresentationQuest[]>([]);
  const [otherQuests, setOtherQuests] = useState<IPresentationQuest[]>([]);

  const getQuestsConnected = async () => {
    const homeQuestsWithRequirements = await Promise.all(
      quests.map(async (quest) => ({
        ...quest,
        hasRequirements: true,
      }))
    );

    const homeQuestsWithStatus = await Promise.all(
      homeQuestsWithRequirements.map(async (quest) => ({
        ...quest,
        status: getMoreQuestsStatus(quest, serverDate),
      }))
    );

    setAcademyQuests(
      homeQuestsWithStatus
        .filter((q) => q.categoryId.toLowerCase() === "academy")
        .sort((a, b) => sortHomeQuestsWithoutConnexion(a, b))
        .slice(0, 3)
    );
    setOtherQuests(
      homeQuestsWithStatus
        .filter((q) => q.categoryId.toLowerCase() !== "academy")
        .sort((a, b) => sortHomeQuestsWithoutConnexion(a, b))
        .slice(0, 3)
    );
  };

  const getQuestsNotConnected = async () => {
    const questsWithStatus = await Promise.all(
      quests.map(async (quest) => ({
        ...quest,
        status: getHomeQuestsStatusWithoutConnexion(quest, serverDate),
      }))
    );

    setAcademyQuests(
      questsWithStatus
        .filter((q) => q.categoryId.toLowerCase() === "academy")
        .sort((a, b) => sortHomeQuestsWithoutConnexion(a, b))
        .slice(0, 3)
    );
    setOtherQuests(
      questsWithStatus
        .filter((q) => q.categoryId.toLowerCase() !== "academy")
        .sort((a, b) => sortHomeQuestsWithoutConnexion(a, b))
        .slice(0, 3)
    );
  };

  useEffect(() => {
    if (isReadyToQuest) {
      getQuestsConnected();
    } else {
      getQuestsNotConnected();
    }
  }, [isReadyToQuest, quests]);

  if (academyQuests.length === 0 && otherQuests.length === 0) return null;

  return (
    <div className="flex flex-col w-full items-center justify-center bg-white px-4 md:px-0 pt-20 md:pt-0">
      <div className="hidden md:block md:text-center py-20 md:py-[120px]">
        <div className="captionText mt-4">{t("quests.subtitle")}</div>
        <div className="display-5 md:display-3 max-w-[750px] uppercase">
          {t("quests.title")}
        </div>
      </div>
      {classrooms.length > 0 ? <Classroom classrooms={classrooms} /> : null}

      <QuestsList
        serverDate={serverDate}
        quests={otherQuests}
        title={t("quests.others.title")}
        subtitle={t("quests.others.availability")}
        description={t("quests.others.description")}
        className="mt-20"
      />
      <div className="flex flex-col gap-3 md:items-center md:text-center py-20 md:py-[120px]">
        <div className="bodyText">{t("quests.description")}</div>
        <div>
          <ButtonLink
            label={tCommon("exploreQuests")}
            onClick={() => router.push(`/quests`)}
            svgIcon="Arrowright"
            variant="black"
            underline
          />
        </div>
      </div>
    </div>
  );
}

export default HomeQuests;
