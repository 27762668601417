import React from "react";
import { useTranslation } from "next-i18next";

function Pok() {
  const { t } = useTranslation("common");

  return (
    <div className="relative w-[280px] cursor-pointer z-10">
      <div className="absolute w-[32px] h-[32px] rotate-45 left-[16px] md:top-[15px] lg:left-[-8px] lg:top-[-18px] bg-gray-700 rounded-sm" />
      <div className="absolute text-white rounded-md captionText top-[15px] md:top-[30px] lg:left-[7px] lg:top-[-52px] p-[10px] pr-8 bg-gray-700">
        {t("pok")}
      </div>
    </div>
  );
}

export default Pok;
