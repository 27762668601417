import { Trans } from "next-i18next";
import { useRouter } from "next/router";
import { IPresentationQuest } from "../../src/interfaces";
import QuestCard from "../QuestCard";

interface QuestsListProps {
  serverDate: Date;
  quests: IPresentationQuest[];
  title: string;
  subtitle: string;
  description: string;
  className?: string;
}

const QuestsList = ({
  serverDate,
  quests,
  title,
  subtitle,
  description,
  className = "",
}: QuestsListProps) => {
  const router = useRouter();

  return (
    <div className={`max-w-[1440px] w-full md:px-14 lg:px-28 ${className}`}>
      <div className="flex flex-col md:flex-row md:gap-4 md:items-center md:mb-16">
        <div className="flex flex-col md:gap-2 md:w-1/2">
          <div className="heading-3 md:heading-1">{title}</div>
          <div className="captionText text-gray-500">
            <Trans
              components={{
                linked: (
                  <button
                    type="button"
                    className="border-b-[1px] border-gray-500 hover:border-white"
                    onClick={() => router.push(`/faq#content9`)}
                  />
                ),
              }}
              i18nKey={subtitle}
            />
          </div>
        </div>
        <div className="bodyText mt-10 md:mt-0 md:w-1/2">{description}</div>
      </div>
      <div className="flex flex-wrap gap-x-[32px] gap-y-7 md:gap-y-14 mt-10">
        {quests.map((quest) => (
          <QuestCard key={quest.id} quest={quest} now={serverDate} />
        ))}
      </div>
    </div>
  );
};

export default QuestsList;
