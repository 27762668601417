import type { NextPage } from "next";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation, Trans } from "next-i18next";
import { Grid, useMediaQuery } from "@material-ui/core";
import Button from "../components/Button";
import SharePurpleArea from "../components/SharePurpleArea";
import { loadCurrentpage } from "../store/slices/app-slice";
import {
  getDifferenceInDays,
  getMetadata,
  getAllPublicQuests,
  getHomePage,
  sharedServerSide,
  getClassroomPage,
} from "../helpers";
import StayAlertArea from "../components/StayAlertArea";
import Pok from "../components/Pok";
import { storeWrapper } from "../store/store";
import { Locale } from "../src/types";
import HeroIcons from "../components/Home/HeroIcons";
import HeroSlider from "../components/Home/HeroSlider";
import VerticalSlides from "../components/Home/VerticalSlides";
import AcademyArea from "../components/AcademyArea";
import { IClassroom, IHomePage, IPresentationQuest } from "../src/interfaces";
import ButtonLink from "../components/Link";
import AnimatedBanner from "../components/Home/AnimatedBanner";
import HomeQuests from "../components/Home/HomeQuests";
import { useLoginContext } from "../hooks";

export interface HomeProps {
  publicQuests: IPresentationQuest[];
  homePage: IHomePage;
  serverDateJSON: Date;
  classrooms: IClassroom[];
}

export const getServerSideProps = sharedServerSide(
  storeWrapper.getServerSideProps(() => async ({ locale }) => {
    const currentLocale = locale as Locale;
    const serverDateJSON = new Date().toJSON();
    const [publicQuests, homePage, metas, academyPage] = await Promise.all([
      getAllPublicQuests(currentLocale),
      getHomePage(currentLocale),
      getMetadata("homepage", currentLocale),
      getClassroomPage(currentLocale, "Academy page"),
    ]);

    return {
      props: {
        publicQuests,
        homePage,
        serverDateJSON,
        metas,
        classrooms: academyPage?.classrooms,
        ...(await serverSideTranslations(currentLocale, ["common", "home"])),
      },
    };
  })
);

const App: NextPage<HomeProps> = function ({
  publicQuests,
  homePage,
  serverDateJSON,
  classrooms,
}) {
  const serverDate = new Date(serverDateJSON);
  const dispatch = useDispatch();
  const router = useRouter();
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("home");
  const { userCompletedQuestsAsynchronous } = useLoginContext();
  const [quests, setQuests] = useState<IPresentationQuest[]>(publicQuests);
  const [currentQuest, setCurrentQuest] = useState<number>(0);
  const [pok, setPok] = useState<number>(0);
  const sliderRef = useRef(null);
  const parentRef = useRef(null);
  const isMd = useMediaQuery("(max-width: 768px)");
  const isLg = useMediaQuery("(max-width: 1024px)");

  useEffect(() => {
    if (userCompletedQuestsAsynchronous.length === 0) {
      setQuests(publicQuests);
    } else {
      setQuests(
        publicQuests.map((quest) => ({
          ...quest,
          completed: userCompletedQuestsAsynchronous.includes(
            quest.collectionId
          ),
          hasRequirements: true,
        }))
      );
    }
  }, [userCompletedQuestsAsynchronous]);

  const handleScroll = (e) => {
    if (sliderRef) {
      if (e.deltaY < 0) {
        sliderRef.current.slickPrev();
      } else if (e.deltaY > 0) {
        sliderRef.current.slickNext();
      }
    }
  };

  useEffect(() => {
    if (!parentRef.current) {
      return;
    }
    parentRef.current.addEventListener("wheel", (e) => handleScroll(e));
  }, [parentRef]);

  useEffect(() => {
    dispatch(loadCurrentpage("Homepage"));
  }, []);

  return (
    <Grid container className="w-full">
      <Grid item xs={12} className="w-full">
        <Grid container className="w-full">
          <div className="relative flex flex-row justify-center w-full overflow-hidden bg-black">
            {!isMd && (
              <img
                className="absolute z-0 w-full "
                src="/images/background.png"
                alt=""
              />
            )}
            <div className="xl:px-[95px] px-4 pt-2 pb-[114px] flex lg:flex-row flex-col justify-between items-center z-0 lg:mt-[145px] mt-[105px] lg:max-w-[1440px] max-w-[568px] xl:gap-[93px] md:gap-[30px] gap-[60px] w-full">
              <div className="text-white md:pt-[70px] md:px-4 lg:w-auto w-full flex-1">
                <div
                  className="display-6 md:display-3 lg:display-2 "
                  data-cy="HeroTitle"
                >
                  <Trans
                    components={{
                      colored: <span className="text-purple" />,
                    }}
                    i18nKey="home:hero.title"
                  />
                </div>
                <div
                  className="text-purple md:pt-4 pt-[12px] heading-3 md:heading-2"
                  data-cy="HeroDescription"
                >
                  {t("hero.subtitle")}
                </div>
                <div className="flex flex-col gap-4 lg:py-20 py-7 md:items-center md:flex-row">
                  <img
                    className="md:w-[144px] w-[87px]"
                    src="/images/proof.png"
                    alt="Proof"
                    data-cy="HeroPokBadge"
                  />
                  <div>
                    <span className="bodyText" data-cy="HeroPokDescription">
                      {t("hero.pok.line1")}
                    </span>
                    <div className="relative flex items-center">
                      <button
                        type="button"
                        onClick={() => setPok(pok ? 0 : 1)}
                        className="flex gap-1 cursor-pointer highlightText"
                      >
                        <span className="border-b" data-cy="HeroPokDefinition">
                          {t("hero.pok.line2")}
                        </span>
                        <img src="/icons/Homepage/question-circle.svg" alt="" />
                      </button>
                      {pok === 1 && (
                        <div className="absolute lg:left-[240px] md:left-[100px] left-[30px] top-[30px] md:top-auto">
                          <Pok />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bodyText">{t("hero.secondSubtitle")}</div>
                <div
                  className={`captionText flex gap-8 mt-8 ${
                    isLg
                      ? "text-white overflow-x-auto scrollbar-hide coming-soon"
                      : "md:w-auto w-full"
                  }`}
                >
                  <HeroIcons />
                </div>
              </div>
              <div className="relative  lg:h-full md:h-[630px] h-[422px] xl:w-[568px] lg:w-[480px] md:w-full w-[288px]">
                <div className="relative w-full h-full md:z-0">
                  <div
                    className={`w-full h-[110%] ${
                      !isMd ? "mask-desktop" : "mask-mobile"
                    }`}
                  >
                    <video
                      className="object-cover h-[110%]"
                      playsInline
                      autoPlay
                      muted
                      loop
                      poster=""
                    >
                      <source
                        src="/videos/quest-background-2.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>

                {homePage.sliderQuests && (
                  <div className="absolute top-0 max-w-[568px]  w-full flex flex-col justify-center">
                    <div className="xl:h-[740px] lg:h-[630px] md:h-[700px] h-[450px]">
                      <HeroSlider
                        sliderQuests={homePage.sliderQuests}
                        serverDateJSON={serverDateJSON}
                        setCurrentQuest={setCurrentQuest}
                      />
                    </div>
                    {homePage.sliderQuests.length > 0 ? (
                      <div className="absolute bottom-0 w-full flex justify-center md:mb-12 mb-8 ">
                        <ButtonLink
                          label={
                            !userCompletedQuestsAsynchronous?.includes(
                              homePage.sliderQuests[currentQuest].collectionId
                            ) &&
                            !getDifferenceInDays(
                              serverDate,
                              new Date(
                                homePage.sliderQuests[currentQuest].startDate
                              )
                            )
                              ? tCommon("start")
                              : tCommon("learnMore")
                          }
                          onClick={() =>
                            router.push(
                              `/quests/${homePage.sliderQuests[currentQuest].slug}`
                            )
                          }
                          svgIcon="Arrowright"
                          variant="white"
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>

          <StayAlertArea />
          {homePage.showComingSoon && (
            <AnimatedBanner
              text={tCommon("status.comingSoon")}
              type="ComingSoon"
            />
          )}

          {homePage.showComingSoon && homePage.comingSoonQuest && (
            <div className="flex flex-row justify-center w-full z-3 bg-primary z-10">
              <div className="flex justify-between flex-col-reverse md:flex-row md:w-[1440px] w-full overflow-hidden">
                <div className="my-auto xl:px-[117px] md:px-10 px-4 md:py-0 py-12">
                  <div className="display-5 mb-4 uppercase">
                    {t("banner.title")}
                  </div>
                  <span className="display-4 lg:display-3">
                    {homePage.comingSoonQuest.partnerName}
                  </span>
                  {homePage.comingSoonQuest.description && (
                    <div className="max-w-[384px] heading-3 mt-[48px]">
                      {homePage.comingSoonQuest.description}
                    </div>
                  )}

                  <div className="mt-[48px]">
                    <Button
                      onClick={() =>
                        router.push(`/quests/${homePage.comingSoonQuest.slug}`)
                      }
                      label={tCommon("discoverQuest")}
                      svgIcon="Arrowright"
                      type="secondary"
                    />
                  </div>
                </div>
                {!isMd ? (
                  <button
                    type="button"
                    className="flex justify-center items-center w-[600px] h-[600px] bg-gray-700"
                    onClick={() =>
                      router.push(`/quests/${homePage.comingSoonQuest.slug}`)
                    }
                  >
                    <img
                      className="lg:w-[600px] md:w-[500px]  h-[600px] object-cover"
                      src={
                        homePage.comingSoonQuest.imageUrl ||
                        "/images/empty-coming-soon-banner.png"
                      }
                      alt="coming soon quest"
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="w-full bg-gray-700"
                    onClick={() =>
                      router.push(`/quests/${homePage.comingSoonQuest.slug}`)
                    }
                  >
                    <img
                      className="w-full h-[321px] object-cover cursor-pointer"
                      src={
                        homePage.comingSoonQuest.imageUrl ||
                        "/images/empty-coming-soon-banner.png"
                      }
                      alt="coming soon quest"
                    />
                  </button>
                )}
              </div>
            </div>
          )}

          <VerticalSlides />

          <HomeQuests
            quests={quests}
            serverDate={serverDate}
            classrooms={classrooms}
          />

          <AcademyArea />

          <SharePurpleArea />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default App;
