import React from "react";
import { useTranslation } from "next-i18next";
import SocialIcons from "../Home/SocialIcons";

function StayAlertArea() {
  const { t } = useTranslation("common");

  return (
    <div className="flex flex-row justify-center w-full z-10 bg-white">
      <div className="md:w-[1440px] w-full xl:px-28 md:px-5 px-4 py-20 flex justify-between md:flex-row flex-col gap-[48px]">
        <div className="xl:max-w-[525px] md:max-w-[380px] w-full">
          <div className="emphasisText md:heading-3">
            {t("alertArea.tagline")}
          </div>
          <div className="display-4 md:display-3 mt-4 uppercase">
            {t("alertArea.title")}
          </div>
        </div>
        <div className="self-center md:w-[400px] xl:w-[592px] w-full">
          <div className="heading-2">{t("alertArea.share")}</div>
          <SocialIcons />
        </div>
      </div>
    </div>
  );
}

export default StayAlertArea;
