import {
  Children,
  FC,
  ReactNode,
  useEffect,
  useState,
  RefObject,
  useRef,
} from "react";
import useElementSize from "../../hooks/use-element-size";

type customChild = ReactNode & {
  ref: RefObject<HTMLElement>;
};

type speedType = "default" | "slow" | "fast";

interface InfiniteBannerProps {
  space: number;
  speed?: speedType;
  children: ReactNode;
}

const getSpeedNumber = (speed: speedType): number => {
  switch (speed) {
    case "slow":
      return 10;
    case "fast":
      return 1;
    default:
      return 5;
  }
};

const InfiniteBanner: FC<InfiniteBannerProps> = ({
  space,
  speed = "default",
  children,
}) => {
  const [childrenWithRefs, setChildrenWithRefs] = useState<customChild[]>([]);
  const [additionalChildren, setAdditionalChildren] = useState<customChild[]>(
    []
  );
  const [childrenWidth, setChildrenWidth] = useState<number>(0);
  const [containerRef, { width: containerWidth }] = useElementSize();
  const [firstRef, { width: firstWidth }] = useElementSize();
  const [secondRef, { width: secondWidth }] = useElementSize();
  const [containerLeft, setContainerLeft] = useState<number>(0);
  const [firstLeft, setFirstLeft] = useState<number>(0);
  const [secondLeft, setSecondLeft] = useState<number>(0);
  const firstLeftRef = useRef<number>(firstLeft);
  const secondLeftRef = useRef<number>(secondLeft);
  const firstWidthRef = useRef<number>(firstWidth);
  const secondWidthRef = useRef<number>(secondWidth);
  firstLeftRef.current = firstLeft;
  secondLeftRef.current = secondLeft;
  firstWidthRef.current = firstWidth;
  secondWidthRef.current = secondWidth;

  useEffect(() => {
    const cWithChildren: customChild[] = [];
    const arrayChildren: ReactNode[] = Children.toArray(children);
    arrayChildren.forEach((child) => {
      const cChild = child as customChild;
      if (!cChild?.ref) {
        throw new Error("At least one child doesn't have a ref attribute!");
      }
      cWithChildren.push(cChild);
    });
    setChildrenWithRefs(cWithChildren);
  }, []);

  useEffect(() => {
    if (childrenWithRefs.length === 0) return;

    let cWidth = 0;
    childrenWithRefs.forEach((child) => {
      if (!child?.ref?.current) return;
      cWidth += child?.ref?.current?.clientWidth || 0;
    });

    setChildrenWidth(cWidth);
  }, [childrenWithRefs]);

  useEffect(() => {
    if (childrenWidth === 0) return;
    const multiple = Math.ceil(
      containerWidth / (childrenWidth + space * (childrenWithRefs.length + 1))
    );

    const cToDisplay: customChild[] = [];
    for (let i = 0; i < multiple; i += 1) {
      cToDisplay.push(children as customChild);
    }
    setAdditionalChildren(cToDisplay);
  }, [childrenWidth, containerWidth]);

  useEffect(() => {
    const speedNumber = getSpeedNumber(speed);
    const timer = setInterval(() => {
      if (firstLeftRef.current + firstWidthRef.current <= 0) {
        setFirstLeft(
          firstLeftRef.current +
            firstWidthRef.current +
            secondWidthRef.current -
            1
        );
      } else {
        setFirstLeft(firstLeftRef.current - 1);
      }

      if (secondLeftRef.current + secondWidthRef.current <= 0) {
        setSecondLeft(
          secondLeftRef.current +
            firstWidthRef.current +
            secondWidthRef.current -
            1
        );
      } else {
        setSecondLeft(secondLeftRef.current - 1);
      }
    }, speedNumber);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setContainerLeft(containerRef.current?.offsetLeft || 0);
  }, [containerWidth]);

  useEffect(() => {
    setFirstLeft(0);
    setSecondLeft(firstWidth);
  }, [containerLeft, firstWidth]);

  return (
    <div
      ref={containerRef}
      className="relative w-full h-full flex items-center overflow-hidden"
    >
      <div
        ref={firstRef}
        className="absolute w-fit"
        style={{ left: `${firstLeft}px` }}
      >
        <div
          className="w-full flex items-center"
          style={{ gap: `${space}px`, paddingLeft: `${space}px` }}
        >
          {children}
          {additionalChildren}
        </div>
      </div>
      <div
        ref={secondRef}
        className="absolute w-fit"
        style={{ left: `${secondLeft}px` }}
      >
        <div
          className="w-full flex items-center"
          style={{ gap: `${space}px`, paddingLeft: `${space}px` }}
        >
          {children}
          {additionalChildren}
        </div>
      </div>
    </div>
  );
};

export default InfiniteBanner;
