import React from "react";
import { useTranslation } from "next-i18next";
import router from "next/router";
import Slider from "react-slick";
import { getDifferenceInDays } from "../../helpers";
import TitleTag from "../TitleTag";
import { IPresentationQuest } from "../../src/interfaces";
import QuestCardPicture from "../QuestCardPicture";

function ComingSoon() {
  const { t } = useTranslation("common");
  return (
    <div className="relative mx-auto md:w-[222px] w-[120px] md:h-[54px] h-[28px] text-center flex items-center">
      <div className="coming_soon w-full h-full">
        <div className="coming_soon-element top-left" />
        <div className="coming_soon-element top-right" />
        <div className="coming_soon-element bottom-left" />
        <div className="coming_soon-element bottom-right" />
      </div>
      <div className="bodyMonoText md:display-5 mx-auto my-auto uppercase">
        {t("status.comingSoon")}
      </div>
    </div>
  );
}

interface SliderProps {
  sliderQuests: IPresentationQuest[];
  serverDateJSON: Date;
  setCurrentQuest: React.Dispatch<React.SetStateAction<number>>;
}
function HeroSlider(props: SliderProps) {
  const { sliderQuests, serverDateJSON, setCurrentQuest } = props;
  const { t: tCommon } = useTranslation("common");
  const serverDate = new Date(serverDateJSON);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentQuest(newIndex);
    },
  };

  const getCategory = (quest: IPresentationQuest) => {
    if (quest.categoryId === "Community")
      return tCommon("questsTypes.communityQuest");
    if (quest.categoryId === "Academy")
      return tCommon("questsTypes.academyQuest");
    return tCommon("questsTypes.partnerQuest");
  };

  if (sliderQuests.length === 0) {
    return (
      <div className="w-full text-center">
        <button
          type="button"
          className="xl:my-6 lg:my-4 md:my-6 my-4 mx-auto xl:w-[272px] w-[50%] rotate-[-2.5deg]"
          onClick={() => null}
        >
          <QuestCardPicture
            picture={{ url: "/images/empty-quest-picture.png", type: "image" }}
            tokenGated={false}
            type="slider"
          />
        </button>

        <div className="mt-8 md:mt-4 mb-2">
          <span className="md:heading-3 captionText">
            {tCommon("moreQuests")}
          </span>
        </div>

        <ComingSoon />
      </div>
    );
  }

  return (
    <Slider {...settings}>
      {sliderQuests.map((item) => (
        <div className="w-full text-center" key={item.id}>
          <button
            type="button"
            className="xl:my-6 lg:my-4 md:my-6 my-4 mx-auto xl:w-[272px] w-[50%] rotate-[-2.5deg]"
            onClick={() => router.push(`/quests/${item.slug}`)}
          >
            <QuestCardPicture
              picture={item.questPicture}
              tokenGated={item.tokenGated}
              type="slider"
            />
          </button>

          <div className="flex justify-center items-center">
            <span className="bodyMonoText uppercase">{getCategory(item)}</span>
          </div>

          <button
            type="button"
            className="relative my-4 text-center md:h-[66px] h-[38px]"
            onClick={() => router.push(`/quests/${item.slug}`)}
          >
            {getDifferenceInDays(serverDate, new Date(item.startDate)) > 0 ? (
              <ComingSoon />
            ) : (
              <div className="mx-auto md:h-[66px] h-[44px] w-[224px] md:w-[400px]">
                {/* this is the desktop version of TitleTag */}
                <TitleTag
                  title={item.title}
                  maxFontSize={175}
                  minFontSize={10}
                  font="font-mono-medium"
                  className="md:block hidden"
                />
                {/* this is the mobile version of TitleTag */}
                <TitleTag
                  title={item.title}
                  maxFontSize={120}
                  minFontSize={10}
                  font="font-mono-medium"
                  className="md:hidden block"
                />
              </div>
            )}
          </button>

          <button
            type="button"
            className="relative flex gap-4 px-4 xl:mb-[8px] md:px-8 bodyText justify-center items-baseline mx-auto"
            onClick={() => router.push(`/quests/${item.slug}`)}
          >
            {item.partnerName && tCommon("featuring")}
            <span className="w-auto heading-3 md:heading-2 uppercase">
              {item.partnerName}
            </span>
          </button>
        </div>
      ))}
    </Slider>
  );
}

export default HeroSlider;
