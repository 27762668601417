import { Trans } from "next-i18next";

function HeroIcons() {
  const icons = ["crown", "star", "badge"];

  return (
    <>
      {icons.map((icon, index) => (
        <div className="min-w-max" key={icon}>
          <img
            className="mb-3"
            src={`/icons/Homepage/${icon}.svg`}
            alt={icon}
          />
          <Trans
            components={{
              colored: <span className="text-purple" />,
            }}
            i18nKey={`home:hero.slider.slide${index + 1}`}
          />
        </div>
      ))}
    </>
  );
}

export default HeroIcons;
