import { Trans, useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { IClassroom } from "../../src/interfaces";
import ClassroomsSlider from "../ClassroomsSlider";

interface ClassroomProps {
  classrooms: IClassroom[];
  className?: string;
}

const Classroom = ({ classrooms, className = "" }: ClassroomProps) => {
  const { t } = useTranslation("home");
  const router = useRouter();

  return (
    <div className={`max-w-[1440px] w-full md:px-14 lg:px-28 ${className}`}>
      <div className="flex flex-col md:flex-row md:gap-4 md:items-center md:mb-16">
        <div className="flex flex-col md:gap-2 md:w-1/2">
          <div className="heading-3 md:heading-1">
            {t("quests.academy.title")}
          </div>
          <div className="captionText text-gray-500">
            <Trans
              components={{
                linked: (
                  <button
                    type="button"
                    className="border-b-[1px] border-gray-500 hover:border-white"
                    onClick={() => router.push(`/faq#content9`)}
                  />
                ),
              }}
              i18nKey={t("quests.academy.availability")}
            />
          </div>
        </div>
        <div className="bodyText mt-10 md:mt-0 md:w-1/2">
          {t("quests.academy.description")}
        </div>
      </div>
      <div className="mt-10">
        <ClassroomsSlider classrooms={classrooms} number={3} />
      </div>
    </div>
  );
};

export default Classroom;
