import React from "react";
import useFitText from "use-fit-text";

interface TitleTagProps {
  maxFontSize: number;
  minFontSize: number;
  title: string;
  font: string;
  className?: string;
}
function TitleTag(props: TitleTagProps) {
  const { maxFontSize, minFontSize, title, font, className } = props;
  const { fontSize, ref } = useFitText({
    maxFontSize,
    minFontSize,
  });
  return (
    <div
      ref={ref}
      style={{ fontSize, lineHeight: fontSize }}
      className={`mx-auto w-full h-full font-inter-medium uppercase object-fill ${font} ${className}`}
    >
      {title}
    </div>
  );
}

export default TitleTag;
