import { FC, useEffect, useRef, useState } from "react";
import InfiniteBanner from "../InfiniteBanner";

interface AnimatedBannerProps {
  text: string;
  type: "ComingSoon" | "Community" | "Academy" | "Partner";
}

const AnimatedBanner: FC<AnimatedBannerProps> = ({ text, type }) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const dotRef = useRef<HTMLSpanElement>(null);
  const [textColor, setTextColor] = useState<string>("");

  useEffect(() => {
    switch (type) {
      case "Community":
        setTextColor("text-purple");
        break;
      case "ComingSoon":
      case "Academy":
        setTextColor("text-primary");
        break;
      case "Partner":
      default:
        setTextColor("text-gray-300");
        break;
    }
  }, []);

  return (
    <div
      className={`w-full h-12 ${textColor} whitespace-nowrap bg-black text-2xl md:leading-8 leading-6`}
    >
      <InfiniteBanner space={28} speed="slow">
        <span ref={textRef} className="heading-2 uppercase">
          {text}
        </span>
        <span ref={dotRef}>&#8226;</span>
      </InfiniteBanner>
    </div>
  );
};

export default AnimatedBanner;
